.blindControls{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;

  width: max-content;
  height: 48px;
}

.blindControls__up{
  padding: 12px;
  width: 48px;
  height: 48px;
  background: rgba(0, 0, 0, 0.11);
  border-radius: 100px;
  cursor: pointer;
}
.blindControls__down{
  padding: 12px;
  width: 48px;
  height: 48px;
  background: rgba(0, 0, 0, 0.11);
  border-radius: 100px;
  cursor: pointer;

}

.blindControls__highlight{
  padding: 12px;
  width: 48px;
  height: 48px;
  background: #EBEBEB;
  border-radius: 100px;
}