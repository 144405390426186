.loading{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: auto; */
}
.grid_container{
  /* flex: 1; */
  /* height: 100%; */
  width: 100%;
  position:absolute;
  top:100vh;
  /* bottom: 0; */  
}
.top_container{
  width:100%;
  height:100%;
  padding:18px 24px 0px 24px
}
.map_container{
  width:100%;
  height: 519px;
  display:flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 16px;
  /* border :2px solid red; */
}
.map_Subcontainer{
  /* height:100%; */
  /* width:70%; */
  margin-left:10%;
  margin-right: 5%;
  /* border :2px solid red; */
  
}
.usage_map{
  width:100%;
  height:100%;
  cursor: pointer;
  
}

.filter_container{
  height:45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
}
.btn{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  width: 44px;
  height: 44px;
  border:0;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(67.955px);
  border-radius: 100px;
}
