.cardWidgetDevices__content{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 16px;

  width: 100%;
  height: 49px;
}

.cardWidgetDevices__content__data{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  height: 49px;
}
.cardWidgetDevices__content__dataValue{
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.87);
}
.cardWidgetDevices__content__dataComponent{
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.6);
}