.dropdown{
  width: 352px;
  height: 48px;
  position: relative;
}
.dropdown__btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
  background: rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  cursor: pointer;
}

.dropdown__options{
  position: absolute;
  padding: 16px 24px;
  background-color: #EBEBEB;
  top: 60px;
  left: 0;
  width: 100%;
  border-radius: 10px;
  text-align: center;
  color: black;
  z-index: 10;
}

.dropdown__options__item{
  cursor: pointer;
  transition: all 0.2s;
  cursor: pointer;
  padding: 16px 24px;
}
.dropdown__options__item:hover{
  background-color: rgba(0, 0, 0, 0.11);
}

.dropdown__selected{
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
}