.cardWidgetDevices__content{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: 56px;
}

.cardWidgetDevices__content__temperature{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  
  width: 103px;
  height: 56px;
}
.cardWidgetDevices__content__temperatureValue{
  /* width: 78px; */
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 56px;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.87);
}
.cardWidgetDevices__content__temperatureUnit{
  width: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
}

.cardWidgetDevices__content__setPoint{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0px;
  gap: 4px;
  
  width: 48px;
  height: 38px;
}

.cardWidgetDevices__content__setPointText{
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.38);
}

.cardWidgetDevices__content__setPointValue{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.6);
}