.sidebar__status{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;

  width: fit-content;
  height: 48px;
  background: rgba(0, 0, 0, 0.11);
  border-radius: 10px;
}