.cardWidgetDevices__content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 100%;
  height: 40px;
}

.cardWidgetDevices__content__text{
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.38);
}
.cardWidgetDevices__content__sourceText{
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.87);
}