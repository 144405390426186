.sidebar{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 24px 80px;
  gap: 40px;

  position: absolute;
  width: 400px;
  height: 100vh;
  left: 0px;
  color:white;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.sidebar__titleContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 352px;
  height: 76px;
}