.map_container{
    width:100%;
    height:519px;
    display:flex;
    justify-content: space-around;
    align-items: center;
  }
  .map_Subcontainer{
    padding-top: 3%;
    width:90%;
    /* height: 100%; */
    margin-left:10%;
    margin-right: 5%;
    /* border :2px solid red; */
  }
  .usage_map{
    width:100%;
    height:100%;
  }

  .loading{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: auto; */
  }