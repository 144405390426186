@font-face {
  /* integration Custom Fonts*/
    font-family:"SF-Pro-Text" ;
    src: local("SF-Pro-Text"),url("./assets/fonts/SF_Pro_Text/SF-Pro-Text-Regular.otf")
}
/* use Custom font in full body  */

/* *, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a { text-decoration: none; } */

html {
  overscroll-behavior: contain;
}

body {
  margin: 0;
  font-family: "SF-Pro-Text";
  
  background-color: #EBEBEB;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
