.grid {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0px 24px 32px;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  scroll-snap-type: inline mandatory;
}




/* .grid::-webkit-scrollbar {
  display: none;
}  */