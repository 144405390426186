.container {
  /* border: 1px solid red; */
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  height: 100vh;
}
.leftcontainer {
  /* border: 1px solid red; */
  padding: 10px 24px;
  background: #b575c4;
  display: flex;
  align-items: center;
}
.rightcontainer {
  padding: 10px 24px;
  background: #ffffff;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}
.subleftContainer {
  width: 100%;
}
.subrightContainer {
  width: 100%;
  /* Auto layout */

  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 40px;
  
  /* border: 1px solid red; */
}
.Logo {
  position: absolute;
  left: 24px;
  top: 34px;
}
.titlecontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
}
.title_box{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}
.titlefirst {
  font-size: 43px;
  line-height: 54px;
  color: #ffffff;

}
.titlesec {
  font-size: 43px;
  line-height: 54px;
  color: rgba(255, 255, 255, 0.6);
}
.details {
  display: flex;
  gap: 16px;
}
.details p {
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}
.login_title {
  display: flex;
  align-items: center;
  gap: 18px;
}
.log_text {
  font-size: 32px;
  line-height: 0;
  color: rgba(24, 24, 24, 0.87);
}
.heading {
  line-height: 0;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.6);
}
.Input_Container{
  display: flex;
  flex-direction: column;
  gap:16px
}
.password_remember_container {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 16px;
}
.remember_text{
  cursor: pointer;
}
.error_container{
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  height: 80px;
  padding: 16px 24px;
  background: rgba(242, 66, 66, 0.1);
  border-radius: 10px;
}
.error_container p{
  font-weight: 500;
 font-size: 14px;
 line-height: 24px;
 color: #F24242;
}
.remember {
  font-weight: 500;
  font-size: 14px;
  color: rgba(24, 24, 24, 0.6);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap:8px
}
.remember_check_box{
  width:18px;
  height:18px;
  border-radius: 4px;
  cursor: pointer;
}
.forget {
  font-weight: 700;
  font-size: 14px;
  color: #b575c4;
  cursor: pointer;
}
.sign_btn {
  height: 50px;
  width:100%;
  border:0;
  background: #b575c4;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.sign_btn:hover{
    background: #8a4c97;
}

/* @media only screen and (max-width: 1024px) {
  .titlefirst {
    font-size: 42px;
    line-height: 42px;
  }
  .titlesec {
    font-size: 42px;
    line-height: 42px;
  }
}
@media only screen and (max-width: 900px) {
  .titlefirst {
    font-size: 35px;
  }
  .titlesec {
    font-size: 28px;
  }
  .heading {
    font-size: 10px;
  }
} */
