.switch {
  position: relative;
  display: inline-block;
  width: 88px;
  height: 48px;
}

.toggleInput {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0000001C;
  transition: 0.3s;
  border-radius: 100px;
}

.switch__slider:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  left: 8px;
  bottom: 8px;
  right: 8px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

/* input:checked + .switch__slider {
  background-color: #00c853;
}


input:checked + .switch__slider:before {
  transform: translateX(40px);
} */

.checked{
  background-color: #00c853;
}
.checked::before{
  transform: translateX(40px);
}
