
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: 40px;
  left: 24px;
  top: 96px;
  cursor: pointer;
}

.navbar__right {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 250px;
  height: 40px;
  cursor: pointer;
}
.navbar__right__buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}
.navbar__right__dashboardButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 16px;
  width: 194px;
  height: 40px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  border: 0;
  cursor: pointer;
}
.navbar__right__buttonText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: rgba(24, 24, 24, 0.6);
}
.navbar__right__avatar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 40px;
  height: 40px;
  border: 2px solid #b575c4;
  border-radius: 100px;
}

.navbar__left {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;

  /* width: 430px; */
  height: 40px;
}
.navbar_floor_icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;
  width: 48px;
  height: 40px;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
}
.navbar__left__chevronContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 16px;
  width: 72px;
  height: 40px;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
  cursor: pointer;
}
.navbar__left__navInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: fit-content;
  height: 24px;
}
.navbar__left__navInfoName {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgba(24, 24, 24, 0.38);
}

.navbar__left__floorInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: fit-content;
  height: 24px;
}
.navbar__left__floorText {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgba(24, 24, 24, 0.87);
  width: max-content;
}
.navbar__left__zoneFloorText {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgba(24, 24, 24, 0.38);
  width: max-content;
}
.navbar_logout_container {
  padding: 8px 0px;
  position: absolute;
  z-index: 100;
  width: 250px;
  height: 136px;
  top: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.navbar_logout_options {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  width: 250px;
  height: 40px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: rgba(24, 24, 24, 0.87);
}
.logout {
  color: #f24242;
}
.logout:hover {
  background: rgba(242, 66, 66, 0.1);
}
.Sidebar_container {
  height: 100vh;
  width: 400px;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 24px;
  gap: 24px;
  background: #ffffff;

  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.3);
}
.navbar_floor_sidebar_heading {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.87);
}
.sidebar_list_data{
display: flex;
flex-direction: row;
align-items: center;
padding: 16px 24px;
gap: 10px;
width: 352px;
height: 64px;
background: #F5F5F5;
border-radius: 10px;
color: rgba(24, 24, 24, 0.6);
cursor: pointer;
}
.sidebar_list{

height: 14px;
font-weight: 500;
font-size: 16px;
line-height: 14px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

}