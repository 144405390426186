.container{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 24px;
  gap: 16px;
  height: 50px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  cursor: pointer;
}
.input{
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
}
.container:focus-within{
  border-color: #5b67eb;
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 0 2px rgba(91, 103, 235, 0.5);
  transition: 0.5s;
}
.input::placeholder{
  font-size: 14px;
  line-height: 14px;
  color: rgba(24, 24, 24, 0.6);
}