
/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a { text-decoration: none; }

html {
  overscroll-behavior: contain;
}

body {
  margin: 0;
  font-family: "SF-Pro-Text";
  background-color: #EBEBEB;
}


.card-widget{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  min-width: 220px;
  height: 144px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(67.955px);
  cursor: pointer;
}

.card-widget__content{
  width: 100%;
}
.card-widget__selected__content{
  width: 172px;
}

.card-widget__title{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}

.card-widget__data{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  height: 16px;
}
.card-widget__valueContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
}
.card-widget__value{
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  width: max-content;
  color: rgba(24, 24, 24, 0.6);
}
.card-widget__value > span{
  font-size: 16px;
}

.page__container{
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.top__container{
  padding: 18px 24px 0px 24px;
  width: 100%;
  height: 100%;
}

.grid__container{
  width: 100%;
  position:absolute;
  /* top:100vh; */
  bottom: 0;  
}

.card-widget-devices{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;

  width: 220px;
  height: 144px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(67.955px);
  cursor: pointer;
}
.card-widget-devices__titleRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  width: 100%;
}
.card-widget-devices__heading{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: 16px;
}
.card-widget-devices__title{
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.87);
}

.zoneText{
  color: white;
}

.sidebar{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 24px 80px;
  gap: 40px;

  position: absolute;
  width: 400px;
  height: 100vh;
  left: 0px;
  color:white;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.3);
}

/* .sidebar__status{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;

  width: fit-content;
  height: 48px;
  background: rgba(0, 0, 0, 0.11);
  border-radius: 10px;
} */

.sidebar__titleContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 352px;
  height: 76px;
  margin-bottom: 40px;
}

.sidebar__title{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: max-content;
  height: 20px;
  color: rgba(255, 255, 255, 0.6);
}
.sidebar__titleContainer__device{
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
}

.sidebar__titleContainer__temp{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 156px;
  height: 96px;
  margin-top: 24px
}
.sidebar__titleContainer__tempValue{
  font-weight: 500;
  font-size: 96px;
  letter-spacing: -0.02em;
}
.sidebar__titleContainer__tempUnit{
  font-weight: 500;
  font-size: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  margin-bottom: 16px
}

.sidebar__titleContainer__sceneText{
  font-weight: 400;
  font-size: 20px;
  line-height: 48px;
}
.sidebar__airQualityData{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 166px;
  height: 141px;
}

.sidebar__airQualityData__component{
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.6);
}
.sidebar__airQualityData__valueCont{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  width: 166px;
  height: 113px;
}
.sidebar__airQualityData__value{
  font-weight: 500;
  font-size: 96px;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.87);
}

.sidebar__airQualityData__unit{
  font-weight: 500;
  font-size: 24px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.6);
}

.sidebar__bottomDescription{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  position: absolute;
  bottom:40px;
  width: 353px;
  height: 88px;
  background: rgba(255, 255, 255, 0.13);
  border-radius: 10px;
}
.sidebar__deviceControl{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 40px;
  /* margin-top: 40px; */
  width: 100%;
  height: 312px;
}

.sidebar__deviceControl__toggle{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 40px;

  width: 176px;
  height: 48px;
}
/* ScrollBar Styles */
.has-scrollbar { padding-bottom: 5px; }

.has-scrollbar::-webkit-scrollbar {
  width: 8px; /* for vertical scroll */
  height: 8px; /* for horizontal scroll */
}

.has-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
  border: 3px solid var(--white);
  border-radius: 20px;
}

.has-scrollbar:hover::-webkit-scrollbar-thumb { background: hsl(0, 0%, 90%); }

.has-scrollbar::-webkit-scrollbar-thumb:hover { background: hsl(0, 0%, 80%); }

@media (min-width: 1181px) {
  .card-widget{
    width: auto;
  }
  .card-widget__content{
    /* text-align: center;
    width: min-content; */
  }
}