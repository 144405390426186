.cardWidget__selected{
  background-color: #558CDD;
  color: #FFFFFF99;
}
.cardWidget__data{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  height: 16px;
}

.cardWidget__totalValue{
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  width: max-content;
  color: rgba(24, 24, 24, 0.38);
}
.cardWidget__totalValue > span{
  font-size: 16px;
}
