.cardWidgetDevices__content{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  
  width: 172px;
  height: 48px;
}

.cardWidgetDevices__content__data{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 75px;
  height: 36px;
}

.cardWidgetDevices__content__song{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.87);
}

.cardWidgetDevices__content__artist{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.6);
}

