.slider{
  width: 202px;
  height: 8px;
  background: #0000001C;
  border-radius: 100px;
  -webkit-appearance: none;
  --range-progress: 50%;
}
.slider::before{
  background: #F5F5F5;;
  width: var(--range-progress);
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

.slider::-moz-range-progress{
  background-color: #F5F5F5;
  height: 100%;
  border-radius: 100px;
}
.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

